import NotFound from "components/NotFound"

function NotFoundPage() {
  return <NotFound />
}

export async function getStaticProps() {
  return {
    props: {},
  }
}

export default NotFoundPage
