import { Flex } from "components/LEGACY/styled-components/Flex"
import PatternPageContainer from "components/utils/PatternPageContainer"
import getpackedLogo from "images/getpacked_Logo_weiss.svg"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import styles from "./NotFound.module.scss"

const NotFound = () => {
  return (
    <PatternPageContainer>
      <Flex column justifyCenter alignCenter height="100%">
        <div style={{ position: "relative", padding: "1rem" }}>
          <Image layout="fixed" src={getpackedLogo} alt="getpacked logo" />
        </div>
        <h1 className={styles.heading}>Seite nicht gefunden</h1>
        <p className={styles.text}>Leider ist da etwas schief gelaufen.</p>
        <Link href={"/welcome"}>
          <a>
            <button className={styles.redirectButton}>Zu getpacked</button>
          </a>
        </Link>
        <Link href={"/admin"}>
          <a>
            <button className={styles.redirectButton}>Zum meinem Betrieb</button>
          </a>
        </Link>
        <Link href={"https://getpacked.de"}>
          <a>
            <button className={styles.redirectButton}>Zur Homepage</button>
          </a>
        </Link>
        <p className={styles.text}>
          Wolltest Du bei einem Betrieb einkaufen?
          <br />
          Bitte schau nochmal auf der Seite des Betriebs, <br />
          wie der Link richtig wäre und versuch es erneut.
        </p>
      </Flex>
    </PatternPageContainer>
  )
}

export default NotFound
